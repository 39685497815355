import React from 'react';
import {
  MdChevronLeft,
  MdChevronRight,
  MdLock,
  MdLogout,
  MdPerson,
  MdWork,
} from 'react-icons/md';

import { QUERY } from '@common/constants';
import { Link } from '@components/Link';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { CSSObject, styled, Theme, useTheme } from '@mui/material/styles';
import { WEB_CLIENT_ROUTE } from '@util/constants';

interface Props {
  openDrawer: boolean;
  toggleDrawer: () => void;
  profileId: string;
}
export const DrawerComponent = (props: Props) => {
  const theme = useTheme();
  const { openDrawer, toggleDrawer, profileId } = props;
  return (
    <>
      <Drawer variant="permanent" open={openDrawer}>
        <DrawerHeader>
          <IconButton onClick={toggleDrawer}>
            {theme.direction === 'rtl' ? <MdChevronRight /> : <MdChevronLeft />}
          </IconButton>
        </DrawerHeader>
        <Divider />

        <List
          sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          component="nav">
          {BOOKING_DRAWER.map((item) => (
            <ListItem button key={item.label} component={Link} href={item.link}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItem>
          ))}
          <Divider light />

          {PROFILE_DRAWER.map((item) => (
            <Link
              href={`${item.link}/${QUERY}`}
              as={`${item.link}/${profileId}`}
              key={item.label}
              style={{ textDecoration: 'none' }}>
              <ListItem button>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.label} sx={{ color: 'black' }} />
              </ListItem>
            </Link>
          ))}
          <Divider light />

          {PASSWORD_DRAWER.map((item) => (
            <ListItem button key={item.label} component={Link} href={item.link}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItem>
          ))}
          {LOGOUT_DRAWER.map((item) => (
            <ListItem button key={item.label} component={Link} href={item.link}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItem>
          ))}
        </List>
        <Divider />
      </Drawer>
    </>
  );
};

const PROFILE_DRAWER = [
  {
    icon: <MdPerson size={22} />,
    label: 'Profile',
    link: WEB_CLIENT_ROUTE.PROFILE_UPDATE,
  },
];

const PASSWORD_DRAWER = [
  {
    icon: <MdLock size={22} />,
    label: 'Change Password',
    link: WEB_CLIENT_ROUTE.CHANGE_PASSWORD,
  },
];

const LOGOUT_DRAWER = [
  {
    icon: <MdLogout size={22} />,
    label: 'Logout',
    link: WEB_CLIENT_ROUTE.LOGOUT,
  },
];

const BOOKING_DRAWER = [
  {
    icon: <MdWork size={22} />,
    label: 'Booking List',
    link: WEB_CLIENT_ROUTE.ACCOUNT,
  },
];

const drawerWidth = 240;

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});
