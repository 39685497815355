import React from 'react';
import { MdMenu } from 'react-icons/md';

import { Link } from '@components/Link';
import { Button, Menu, MenuItem } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Box } from '@mui/system';
import { WEB_CLIENT_ROUTE } from '@util/constants';

export const LoggedUserAppBar = () => {
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = 'primary-search-account-menu-mobile';

  const renderMobileMenu = () => (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}>
      <MenuItem divider autoFocus>
        {faqButton('text')}
      </MenuItem>
      <MenuItem divider>{contactUsButton('text')}</MenuItem>
      <MenuItem divider>{bookiOnlineButton('text')}</MenuItem>
      <MenuItem divider>{phoneButton('text')}</MenuItem>
    </Menu>
  );

  const contactUsButton = (variant: any) => (
    <Button
      variant={variant}
      size="large"
      aria-label="contact us"
      component={Link}
      href={`/#${WEB_CLIENT_ROUTE.CONTACT}`}
      color="inherit">
      Contact
    </Button>
  );
  const bookiOnlineButton = (variant: any) => (
    <Button
      variant={variant}
      size="large"
      aria-label="Book Online"
      component={Link}
      href={WEB_CLIENT_ROUTE.CREATE_JOB}>
      Book Online
    </Button>
  );
  const faqButton = (variant: any) => (
    <Button
      variant={variant}
      size="large"
      aria-label="FAQs"
      color="inherit"
      component={Link}
      href={WEB_CLIENT_ROUTE.FAQ}>
      FAQ
    </Button>
  );
  const phoneButton = (variant: any) => (
    <Button
      variant={variant}
      size="large"
      aria-label="Contact us on 0333 335 5172"
      color="inherit"
      component={Link}
      href="tel:+44 3333355172">
      0333 335 5172
    </Button>
  );
  return (
    <>
      <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 3 }}>
        {faqButton('text')}
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 3 }}>
        {contactUsButton('text')}
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 3 }}>
        {bookiOnlineButton('contained')}
      </Box>

      <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 3 }}>
        {phoneButton('text')}
      </Box>

      <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
        <IconButton
          size="large"
          aria-label="show more"
          aria-controls={mobileMenuId}
          aria-haspopup="true"
          onClick={handleMobileMenuOpen}
          color="inherit">
          <MdMenu />
        </IconButton>
      </Box>
      {renderMobileMenu()}
    </>
  );
};
