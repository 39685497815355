import React from 'react';
import {
  IoLogoFacebook,
  IoLogoInstagram,
  IoLogoLinkedin,
  IoLogoTwitter,
} from 'react-icons/io';

import { Link } from '@components/Link';
import { Grid, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import { WEB_CLIENT_ROUTE } from '@util/constants';

export const Footer = () => {
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        padding: 10,
        backgroundColor: '#f4f4f4',
        textAlign: 'center',
      }}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4} sx={{}}>
          <Stack>
            <div
              style={{
                fontSize: 20,
                color: palette.primary.main,
                fontWeight: 500,
              }}>
              Qeeper
            </div>
            <div style={{ marginTop: 25 }}>Waterhouse Square</div>
            <div>Wework 3, 138 Holborn</div>
            <div>London EC1N 2SW</div>
            <div style={{ marginTop: 10 }}>0333 335 5172</div>
            <Link href="mailto: request@qeeper.co.uk" style={{ marginTop: 25 }}>
              request@qeeper.co.uk
            </Link>
            <div style={{ marginTop: 25 }}>All rights reserved.</div>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={4} sx={{}}>
          <Stack>
            <div style={{ fontSize: 20 }}>Links</div>
            <Link
              noWrap
              href={WEB_CLIENT_ROUTE.BOOKING}
              color="inherit"
              hidden={true}
              style={{ marginTop: 25 }}>
              Book Online
            </Link>
            <Link
              noWrap
              href={WEB_CLIENT_ROUTE.FAQ}
              color="inherit"
              style={{ marginTop: 25 }}>
              FAQ
            </Link>
            <Link
              noWrap
              href={WEB_CLIENT_ROUTE.COOKIE_POLICY}
              color="inherit"
              style={{ marginTop: 25 }}>
              Cookie Policy
            </Link>
            <Link
              noWrap
              href={WEB_CLIENT_ROUTE.PRIVACY_POLICY}
              color="inherit"
              style={{ marginTop: 25 }}>
              Privacy Policy
            </Link>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={4} sx={{}}>
          <Stack>
            <div style={{ fontSize: 20 }}>Follow us:</div>
            <div style={{ marginTop: 25 }}>
              <Link
                style={{ marginRight: 10 }}
                color="inherit"
                href="https://linkedin.com/company/qeeper"
                target="_blank">
                <IoLogoLinkedin size={30} />
              </Link>
              <Link
                style={{ marginRight: 10 }}
                color="inherit"
                href="https://twitter.com/qeeper_home"
                target="_blank">
                <IoLogoTwitter size={30} />
              </Link>
              <Link
                style={{ marginRight: 10 }}
                color="inherit"
                href="https://www.facebook.com/qeeper.home"
                target="_blank">
                <IoLogoFacebook size={30} />
              </Link>
              <Link
                style={{ marginRight: 10 }}
                color="inherit"
                href="https://www.instagram.com/qeeper_home"
                target="_blank">
                <IoLogoInstagram size={30} />
              </Link>
            </div>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};
