import { Box } from '@mui/system';

import { DrawerHeader } from './drawer/DrawerComponent';
import { Footer } from './Footer';
import { HeaderConnector } from './HeaderConnector';

export const ClientLayout = ({ children }: any) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <HeaderConnector />

      <Box sx={{ width: '100%' }}>
        <Box component="main" sx={{ flexGrow: 1, minHeight: '100vh' }}>
          <DrawerHeader />
          {children}
        </Box>
        <Footer />
      </Box>
    </Box>
  );
};

export const getClientLayout = (page: any) => (
  <ClientLayout>{page}</ClientLayout>
);
